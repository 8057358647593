import { useRef, useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./index.css";
import ReactLoading from "react-loading";

const App = () => {
  const searchInput = useRef(null);
  const [images, setImages] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);

  const fetchImages = async () => {
    setFetching(true);
    const searchedText = searchInput.current.value;
    const apiUrl = `https://owrakaeci6.execute-api.eu-north-1.amazonaws.com/test?docRefUrl=${searchInput.current.value}`;
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response as JSON
      })
      .then((data) => {
        // Handle the data received from the server
        setImages(data);
        console.log("Data received:", data);
        setFetching(false);

        if (data.matching_templates)
          setRecentSearches(new Set([...recentSearches, searchedText]));
      })
      .catch((error) => {
        // Handle errors, such as network issues or non-successful responses
        console.error("Error:", error);
        setFetching(false);
      });
  };

  const resetSearch = () => {
    fetchImages();
  };

  const handleSearch = (event) => {
    event.preventDefault();
    console.log(searchInput.current.value);
    resetSearch();
  };

  const handleSelection = (selection) => {
    searchInput.current.value = selection;
    resetSearch();
  };

  return (
    <div className="container">
      <h1 className="title font-black">
        <u>Find Matching Templates</u>
      </h1>
      <div className="search-section">
        <Form onSubmit={handleSearch}>
          <br></br>
          <Form.Control
            type="search"
            placeholder="Paste a document reference url to search..."
            className="search-input"
            ref={searchInput}
          />
          <Button
            variant="primary"
            className="search-btn"
            type="submit"
            onClick={handleSearch}
          >
            Search
          </Button>
        </Form>
      </div>
      <div className="filters">
        {[...recentSearches].map((x) => (
          <div onClick={() => handleSelection(x)}>{x}</div>
        ))}
      </div>

      {isFetching ? (
        <div className="loader">
          <ReactLoading
            type="spinningBubbles"
            color="#7676d7"
            height={100}
            width={50}
          />
        </div>
      ) : (
        <div>
          {" "}
          {images?.matching_templates && (
            <div>
              <h2 className="font-black">Source Template</h2>
              <div className="source-image-container">
                <img
                  src={images.template_url}
                  alt={""}
                  className="source-image"
                />
              </div>
            </div>
          )}
          {images?.matching_templates && (
            <h2 className="font-black">Matching Templates</h2>
          )}
          <div className="images">
            {images?.matching_templates?.map((image, index) => (
              <div className="image-container">
                <img
                  key={index}
                  src={image.template_url}
                  alt={""}
                  className="image"
                />
                <p className="font-black" style={{ letterSpacing: "0.5px" }}>
                  {" "}
                  {image.metadata.category_list}
                </p>
                <h4>
                  {" "}
                  Accuracy: {(image.metadata.accuracy * 100).toFixed(2)}%
                </h4>
              </div>
            ))}
          </div>
         {images?.matching_templates&& <div style={{ border: "1px solid #f3f3f3" }}></div>}
          {!isFetching && (
            <div className="example">
              <h3 className="font-black" style={{ "padding-bottom": "10px" }}>
                Try out with these urls or paste your own url.{" "}
              </h3>
              <div>
                <p>
                  https://storage.documents.cimpress.io/v3/documents/00098e9e-fc63-4710-9189-17e66a192220/revisions/8d22f698-bab9-4297-a6be-cd467fe18b66/docref
                </p>
                <p>
                  https://storage.documents.cimpress.io/v3/documents/0006b435-720c-4d7e-9c1a-2cc2294601c0/revisions/34822f4e-d692-4248-92f5-fa5313bd5924/docref
                </p>
                <p>
                  https://storage.documents.cimpress.io/v3/documents/005e03c4-ef78-4959-b944-8519ccf51657/revisions/0e7c372b-50db-4670-8851-152992abc813/docref
                </p>
                <p>
                  https://storage.documents.cimpress.io/v3/documents/0051d63a-9dd7-4b5d-806c-67f540154511/revisions/833f32dc-5e67-4841-a416-03bdba7ad7b4/docref
                </p>
                <p>
                  https://storage.documents.cimpress.io/v3/documents/000750fa-2e2d-4f58-9e11-5541ea0dd6bf/revisions/6c4e1d5c-a6c5-40e0-816e-086c96babff1/docref
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
